var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-select",
    {
      on: { change: _vm.handleSpeedhange },
      model: {
        value: _vm.speed,
        callback: function ($$v) {
          _vm.speed = $$v
        },
        expression: "speed",
      },
    },
    _vm._l(_vm.speedArray, function (d) {
      return _c("a-select-option", { key: d.id }, [
        _vm._v(" " + _vm._s(d.name) + " "),
      ])
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }