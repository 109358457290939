<template>
  <a-select @change="handleSpeedhange" v-model="speed">
    <a-select-option v-for="d in speedArray" :key="d.id">
      {{ d.name }}
    </a-select-option>
  </a-select>
</template>

<script>
export default {
  props: {
    isSyncVideo: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      videoSpeed: [
        {
          id: 0.5,
          name: '0.5X'
        },
        {
          id: 1,
          name: '正常'
        },
        {
          id: 1.25,
          name: '1.25X'
        },
        {
          id: 1.5,
          name: '1.5X'
        },
        {
          id: 2,
          name: '2X'
        }
      ],
      trackSpeed: [
        {
          id: 1,
          name: '正常'
        },
        {
          id: 2,
          name: '2倍'
        },
        {
          id: 4,
          name: '4倍'
        },
        {
          id: 8,
          name: '8倍'
        },
        {
          id: 16,
          name: '16倍'
        }
      ],
      speedArray: [],
      speed: 1
    }
  },
  watch: {
    isSyncVideo(newVal, oldVal) {
      console.log('isSyncVideo')
      if (newVal) {
        this.speedArray = [...this.videoSpeed]
        if ([0.5, 1, 1.25, 1.5, 2].indexOf(this.speed) < 0) {
          this.speed = 1
          this.handleSpeedhange()
        }
      } else {
        this.speedArray = [...this.trackSpeed]
        if ([1, 2, 4, 8, 16].indexOf(this.speed) < 0) {
          this.speed = 1
          this.handleSpeedhange()
        }
      }
    }
  },
  created() {
    this.speedArray = this.isSyncVideo ? this.videoSpeed : this.trackSpeed
  },
  methods: {
    handleSpeedhange(e) {
      this.$emit('onSpeedChange', this.speed)
    }
  }
}
</script>
